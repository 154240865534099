import type { EnvMapConfig, EnvVar } from "./types";

const env: EnvMapConfig<EnvVar> = {
  dev: {
    FEP_URL: "https://fep-euram-dev.msd-identity.com",
    IMTOKEN_PROFILE_URL: "https://deeplink-dev.msd-identity.com",
    IMTOKEN_UNSUBSCRIBE_URL: "https://consents-dev.msd-identity.com"
  },
  devqa: {
    FEP_URL: "https://fep-euram-devqa.msd-identity.com",
    IMTOKEN_PROFILE_URL: "https://deeplink-test.msd-identity.com",
    IMTOKEN_UNSUBSCRIBE_URL: "https://consents-test.msd-identity.com"
  },
  sit: {
    FEP_URL: "https://fep-euram-sit.msd-identity.com",
    IMTOKEN_PROFILE_URL: "https://deeplink-euram-sit.msd-identity.com",
    IMTOKEN_UNSUBSCRIBE_URL: "https://consents-euram-sit.msd-identity.com"
  },
  prod: {
    FEP_URL: "https://fep-euram.msd-identity.com",
    IMTOKEN_PROFILE_URL: "https://deeplink-euram-prod.msd-identity.com",
    IMTOKEN_UNSUBSCRIBE_URL: "https://consents-euram-prod.msd-identity.com"
  }
};

export default env;
