import type { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { gigya } from "@fep/services";

export async function getAccountInfo(): Promise<ISapAccount> {
  const options = {
    include: "preferences, profile, data",
    extraProfileFields: "address, phones, languages, education"
  };
  return gigya.getAccountInfo(options);
}
