export const isValidLuhn = (number: number | string) => {
  const digits = number.toString().split("").reverse().map(i => parseInt(i));
  const [controlSum, ...payload] = digits;
  const computedSum = payload.reduce((sum: number, num, i) => {
    const isEven = i % 2 === 0;
    let toAdd = num;
    if (isEven) {
      toAdd = num * 2;
      if (num >= 5) toAdd -= 9;
    }
    sum += toAdd;
    return sum;
  }, 0);
  return controlSum === (10 - (computedSum % 10)) % 10;
};
