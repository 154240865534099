import { FepError } from "@fep/services";

export async function logout(): Promise<void> {
  return new Promise<void>((resolve, reject) => window.gigya.accounts.logout({
    callback: response => {
      if (response.errorCode) {
        return reject(new FepError(`${response.errorMessage}`));
      }

      resolve();
    }
  }));
}
