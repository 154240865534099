import { showLoginScreen } from "./showLoginScreen";
import { showScreenSet } from "@fep/gigyaFunction";
import { AuthService, CommunicationConsentService, getPageLocation, loadScript, logger } from "@fep/services";
import { getEnv } from "@fep/env";
import type { Stage } from "@fep/env/types";
import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";

const env = getEnv(process.env.STAGE as Stage);

export async function showCommunicationPreferencesScreen(options: Omit<IShowScreenOptions, "redirect"> | string) {
  try {
    const { pathname, search } = getPageLocation();
    let showScreenOptions: IShowScreenOptions = { redirect: pathname + search };
    if (typeof options === "string") {
      logger.warn(
        "Deprecated API usage, showCommunicationPreferencesScreen should be called with object instead of string"
      );
      showScreenOptions.containerID = options;
    } else {
      showScreenOptions = { ...showScreenOptions, ...options };
    }

    const containerId = showScreenOptions.containerID ?? "fep-main";
    const authService = new AuthService(showScreenOptions);
    await authService.handleAuthentication();

    const externalBundleUrl = new URL(`${env.FEP_URL}/external/communication-preferences.js`);

    await loadScript(externalBundleUrl);

    const { market, lang } = window.fep;

    const consentService = new CommunicationConsentService();

    window.communicationPreferences.render({
      market,
      lang,
      consentService,
      containerId
    });
  } catch (error) {
    logger.error(error);
  }
}

export async function showLegacyCommunicationPreferencesScreen(containerID?: string) {
  // In case of removing isLoggedIn function, please add here loadGigya function call.

  await window.fep.functions.isLoggedIn().then(async result => {
    if (result) {
      await showScreenSet({ screenSet: "Common-screenSet", containerID, screenSetKey: "communication" });
    } else {
      await showLoginScreen({ redirect: window.location.pathname });
    }
  });
}
