import { showScreenSet } from "@fep/gigyaFunction";

export async function showCreatePasswordScreen(containerID?: string) {
  const urlParams = new URLSearchParams(window.location.search);
  const prtoken = urlParams.get("prtoken");
  const imtoken = urlParams.get("imtoken");
  let token = prtoken;
  if (imtoken && !prtoken) {
    token = imtoken;
  }
  const startScreen = token ? "gigya-create-password-screen" : "gigya-create-password-screen-failed";

  await showScreenSet({ screenSet: "Common-screenSet", containerID, screenSetKey: "createPassword", startScreen });
}
