export function loadScript(src: URL) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = src.toString();

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
}
