import { IGigyaResponse } from "@fep/interfaces";

export const handleGigyaResponse = <T extends IGigyaResponse>(
  response: T,
  resolve: (data: T) => void,
  reject: (reason: string) => void
) => {
  if (response.errorCode === 0) {
    resolve(response);
  } else {
    reject(response.errorMessage as string);
  }
};
