import { findParentWithChild } from "./findParentWithChild";

export function toggleErrorVisibility(inputElement: HTMLInputElement, message: string) {
  const errorElement = findParentWithChild(inputElement, "gigya-error-msg").querySelector(".gigya-error-msg");

  if (errorElement) {
    setTimeout(() => {
      errorElement.textContent = message;
      errorElement.classList.value = "fep__field-error gigya-error-msg gigya-error-msg-active";
    }, 0);
  }
}
