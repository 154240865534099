import { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { showScreenSet } from "@fep/gigyaFunction";

export async function showProvidePasswordScreen(options: IShowScreenOptions) {
  await showScreenSet({
    screenSet: "Common-screenSet",
    containerID: options.containerID,
    screenSetKey: "providePassword",
    redirect: options.redirect,
    registrationPath: options.registrationPath
  });
}
