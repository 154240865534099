import type { ICookieData } from "@fep/interfaces";

export function deleteSpecificCookie(cookieData: ICookieData) {
  const { name, path, domain, secure } = cookieData;
  let cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path};`;

  if (domain) {
    cookieString += ` domain=${domain};`;
  }

  if (secure) {
    cookieString += " Secure";
  }

  document.cookie = cookieString;
}
