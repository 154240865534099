import { loadScript } from "@fep/services";

export async function loadIntPhoneScript() {
  const intlTelInputLink = "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.4/build/js/intlTelInput.min.js";

  if (document.querySelectorAll(`script[src="${intlTelInputLink}"]`).length === 0) {
    const intlTelInputUrl = new URL(intlTelInputLink);
    await loadScript(intlTelInputUrl);
  }
}
