import { CaptureEvent } from "../captureEvent";
import { getGaScreenName } from "../screenNameMapper";

export class ValidationEvents extends CaptureEvent {
  screen = "";

  fieldValidationFailedEvent(metadata: Record<string, string>) {
    this.validateMetadata(metadata, ["field", "screen"]);

    const fieldName = metadata.field;
    const screenName = metadata.screen;
    const label = `Field validation failed on ${fieldName}`;
    this.screen = getGaScreenName(screenName);

    this.captureEvent({
      ...metadata,
      action: "fail",
      id: "field_validation_failed",
      label: label
    });
  }
}
