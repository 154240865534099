import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { AuthService, getPageLocation, logger } from "@fep/services";
import { showScreenSet } from "@fep/gigyaFunction";

export async function showMyProfileScreen(options: Omit<IShowScreenOptions, "redirect"> | string) {
  const { pathname, search } = getPageLocation();
  let showScreenOptions: IShowScreenOptions = { redirect: pathname + search };
  if (typeof options === "string") {
    logger.warn(
      "Deprecated API usage, showCommunicationPreferencesScreen should be called with object instead of string"
    );
    showScreenOptions.containerID = options;
  } else {
    showScreenOptions = { ...showScreenOptions, ...options };
  }
  const { containerID } = showScreenOptions;
  const authService = new AuthService(showScreenOptions);
  await authService.handleAuthentication(true);

  await showScreenSet({ screenSet: "Common-screenSet", containerID, screenSetKey: "myProfile" });
}
