import { IFepConsents } from "@msd-cex/sap-cdc-shared";

export class ConsentStateManager {
  private consentStateHistory: {
    [consentId: string]: {
      latestConsentValue: string | undefined;
      latestConsentStatus: boolean;
    };
  } = {};

  constructor(private initialConsentData: IFepConsents | undefined) {}

  public getPreviousConsent(consentId: string) {
    const initialConsent = this.getInitialConsentById(consentId);
    return this.consentStateHistory[consentId] || {
      latestConsentValue: initialConsent?.consentValue,
      latestConsentStatus: initialConsent?.isConsentGranted ?? false
    };
  }

  public updateConsentState(consentId: string, consentValue: string | undefined, isConsentGranted: boolean) {
    this.consentStateHistory[consentId] = {
      latestConsentValue: consentValue,
      latestConsentStatus: isConsentGranted ?? false
    };
  }

  private getInitialConsentById(consentId: string) {
    return this.initialConsentData?.find(consent => consentId === consent.consentId);
  }
}
