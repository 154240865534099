import { logger } from "@fep/services";
import { ILoV } from "@fep/interfaces/gigya/thisScript/globalConf/iLoV";

export function clearSelectOptions(selectElement: HTMLSelectElement): void {
  while (selectElement.options.length > 0) {
    selectElement.options.remove(0);
  }
}

export function populateSelectWithOptions(selectElement: HTMLSelectElement, lov: ILoV[]): void {
  for (const { value, text, selected, disabled } of lov) {
    const option = document.createElement("option");

    option.value = value;
    option.textContent = text;
    option.selected = selected;
    option.disabled = disabled;

    selectElement.add(option);
  }
}

export function translate(lov: ILoV) {
  const { translations } = window.gigya.thisScript.globalConf;

  return {
    ...lov,
    text: translations[lov.text]
  };
}

export function fillLoVs() {
  const language = window.fep.lang === "en" ? "default" : window.fep.lang;
  const { market } = window.fep;
  const { translations, lovsPerMarket, selectedTranslation } = window.gigya.thisScript.globalConf;

  const marketLoVs = lovsPerMarket[market];

  if (!translations && !selectedTranslation) {
    logger.error("Failed to get translations");
    return;
  }

  if (!translations) {
    window.gigya.thisScript.globalConf.translations = selectedTranslation[language] as Record<string, string>;
  }

  for (const { id, lov } of Object.values(marketLoVs)) {
    const field = document.querySelector<HTMLSelectElement>(`#${id}`);

    if (!field) {
      logger.info(`Cannot fill field ${id}`);
      continue;
    }

    const translatedLoV = lov.map(translate);

    clearSelectOptions(field);
    populateSelectWithOptions(field, translatedLoV);
  }
}
