export function findParentWithChild(element: HTMLInputElement, childClass: string): HTMLElement {
  let currentElement: HTMLInputElement = element;
  // continue searching up the DOM tree until we find parent with child element
  while (!currentElement.querySelector(`.${childClass}`)) {
    if (currentElement.querySelector(`.${childClass}`)) {
      return currentElement;
    }

    // move up to he parent element
    currentElement = currentElement.parentElement as HTMLInputElement;
  }

  return currentElement;
}
