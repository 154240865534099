import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { showLoginScreenWithUrlContext } from "@fep/gigyaFunction/showLoginScreenWithUrlContext";

export async function showLoginScreen(options: IShowScreenOptions): Promise<void> {
  const showLoginScreenOptions: IShowScreenOptions = {
    containerID: options.containerID,
    redirect: `${window.location.origin}${options.redirect.startsWith("/") ? "" : "/"}${options.redirect}`,
    registrationPath: `${window.location.origin}${options.registrationPath?.startsWith("/") ? "" : "/"}${options.registrationPath}`
  };
  showLoginScreenWithUrlContext(showLoginScreenOptions);
}
