import type { ICaptureEventOptions } from "@fep/interfaces";
import { FepConfig, FepError } from "@fep/services";
import { bindMethods } from "@fep/util";

export interface CaptureEventMixin {
  config: FepConfig;
}

export abstract class CaptureEvent {
  abstract screen: string;

  // mixin
  config: FepConfig;

  constructor(mixin: CaptureEventMixin) {
    this.config = mixin.config;
    bindMethods(this);
  }

  protected captureEvent(captureEvent: ICaptureEventOptions, screen?: string) {
    if (typeof this.config.data.gaEnabled !== "boolean") {
      throw new FepError(
        "Invalid configuration: 'gaEnabled' must be a boolean."
      );
    }

    if (this.config.data.gaEnabled) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        screen: screen ?? this.screen,
        ...captureEvent,
        event: "sapCdcEvent",
        language: window.fep.lang,
        market: window.fep.market,
        portal: window.fep.portal
      });
    }
  }

  protected validateMetadata(metadata: Record<string, string | object>, fields: string[]) {
    fields.forEach(field => {
      if (!metadata[field]) {
        throw new FepError(`Property '${field}' must be specified in metadata.`);
      }
    });
  }
}
