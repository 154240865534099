type ScreenNameMapping = {
  [key: string]: string;
};

const screenNameMapper: ScreenNameMapping = {
  "my-account-screen": "My Profile",
  "gigya-register-screen-step1": "Registration Step 1",
  "gigya-register-screen-step2": "Registration Step 2",
  "gigya-register-screen-step3": "Registration Step 3",
  "link-account-screen": "Social Link Account",
  "gigya-login-screen": "Login",
  "missing-data-screen": "Post Login",
  "social-post-login-screen": "Social Post Login",
  "gigya-change-password-screen": "Change Password",
  "communication-screen": "Communication Preferences",
  "gigya-create-password-screen": "Create Password",
  "gigya-forgot-password-screen": "Forgot Password",
  "provide-password-screen": "Reset Password"
};

export function getGaScreenName(internalName: string): string {
  const screenName = screenNameMapper[internalName] || internalName;
  return screenName;
}
