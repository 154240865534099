import type { FepFunctions, IConfig } from "@fep/interfaces";
import * as functions from "@fep/functions";
import { injectFonts, FepError, GoogleAnalytics, ga } from "@fep/services";
import { FepLogger, logger } from "@fep/services/logger";
import { FepConfig, config } from "@fep/services/config";
import { FepGigyaEvents, events } from "@fep/services/config/events";
import { GigyaLibManager, gigyaLibManager } from "@fep/services/gigya";
import { version } from "../package.json";
import { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { handleBFCacheRestore } from "@fep/util";

export interface FepMixin {
  logger: FepLogger;
  config: FepConfig;
  events: FepGigyaEvents;
  gigyaLibManager: GigyaLibManager;
  ga: GoogleAnalytics;
}

export class Fep {
  private _functions?: FepFunctions;

  // mixin
  logger: FepLogger;
  config: FepConfig;
  events: FepGigyaEvents;
  gigyaLibManager: GigyaLibManager;
  ga: GoogleAnalytics;

  constructor(mixin: FepMixin) {
    this.logger = mixin.logger;
    this.config = mixin.config;
    this.events = mixin.events;
    this.gigyaLibManager = mixin.gigyaLibManager;
    this.ga = mixin.ga;

    handleBFCacheRestore();
    injectFonts();
  }

  get version(): string {
    return version;
  }

  get lang(): string {
    return this.config.data.lang ?? "en";
  }

  get market(): string {
    return this.config.data.market ?? "";
  }

  get portal(): string {
    return this.config.data.portal ?? "";
  }

  get apiKey(): string {
    return this.config.data.apiKey ?? "";
  }

  get ssoEnabled(): boolean {
    return !!this.config.data.ssoEnabled;
  }

  // alias to expose fep.functions on window.fep.fn
  get fn(): FepFunctions {
    return this.functions;
  }

  get functions(): FepFunctions {
    if (!this._functions) {
      const onBefore = async () => {
        await this.gigyaLibManager.init();
      };

      // decorate every fep.functions.methodName
      this._functions = new Proxy(functions, {
        get(target, prop, receiver) {
          const original = Reflect.get(target, prop, receiver);
          if (typeof original === "function") {
            return async (...args: Parameters<typeof original>) => {
              await onBefore();
              return original.apply(this, args);
            };
          }
        }
      }) as unknown as FepFunctions;
    }

    return this._functions;
  }

  /**
   * @deprecated keeping for backward compatibility.
   */
  set onLogin(onLoginCallback: (account: ISapAccount) => void | Promise<void>) {
    this.logger.warn(`Please do not set onLogin directly. Events should be set via fep.addEventListener.`);

    this.events.onLogin = onLoginCallback;
  }

  /**
   * @deprecated keeping for backward compatibility.
   */
  set onLogout(onLogoutCallback: () => void | Promise<void>) {
    this.logger.warn(`Please do not set onLogout directly. Events should be set via fep.addEventListener.`);

    this.events.onLogout = onLogoutCallback;
  }

  addEventListener(...args: Parameters<typeof this.events.addEventListener>): void {
    return this.events.addEventListener(...args);
  }

  /**
   * @deprecated keeping for backward compatibility
   *
   * Just in case this public method was used anywhere.
   */
  setConfig(config: IConfig) {
    this.logger.warn(`Please do not call setConfig directly. This method is redundant and will be decomissioned soon.
      The configuration object should be passed within the FEP Script. Refer to the FEP Integration Guide for details.`);

    this.config.data = config;
  }

  /**
   * @deprecated keeping for backward compatibility
   */
  async loadGigya(configData: IConfig | undefined = this.config.data) {
    this.logger.warn(`Please do not call loadGigya directly. This method is redundant and will be decomissioned soon.
      The loading is checked and executed automatically after calling any first method from fep.functions.methodName.`);

    await this.gigyaLibManager.init(configData);
  }

  isFepError(error: Error): boolean {
    return error instanceof FepError;
  }
}

Object.defineProperty(window, "fep", {
  value: new Fep({ logger, config, events, gigyaLibManager, ga }),
  writable: false,
  enumerable: true,
  configurable: true
});
