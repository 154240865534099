import type {
  IGetScreenSetsOptions,
  IGetScreenSetsResponse,
  IGetAccountInfoOptions,
  ISetAccountInfoOptions
} from "@fep/interfaces/gigya/accounts";
import { type ISapAccount, mapResponseToAccount } from "@msd-cex/sap-cdc-shared";
import { FepError } from "@fep/services";
import { handleGigyaResponse } from "./util";

export class GigyaFacade {
  private static _instance: GigyaFacade;

  static getInstance(): GigyaFacade {
    if (!this._instance) {
      this._instance = new GigyaFacade();
    }

    return this._instance;
  }

  async getAccountInfo(options: IGetAccountInfoOptions = {}): Promise<ISapAccount> {
    return new Promise<ISapAccount>((resolve, reject) =>
      window.gigya.accounts.getAccountInfo({
        callback: response => {
          if (response.errorCode) {
            return reject(new FepError(response.errorMessage));
          }

          const accountData = mapResponseToAccount(response);

          resolve(accountData);
        },
        ...options
      })
    );
  }

  async setAccountInfo(options: ISetAccountInfoOptions) {
    return new Promise<void>((resolve, reject) =>
      window.gigya.accounts.setAccountInfo({
        callback: response => {
          if (response.errorCode) {
            return reject(new FepError(response.errorMessage));
          }
          resolve();
        },
        ...options
      })
    );
  }

  async isLoggedIn(): Promise<boolean> {
    return new Promise(resolve =>
      window.gigya.accounts.session.verify({
        callback: ({ errorCode }) => resolve(!errorCode)
      })
    );
  }

  async getScreenSets(params: Omit<IGetScreenSetsOptions, "callback">) {
    return new Promise<IGetScreenSetsResponse>((resolve, reject) => {
      window.gigya.accounts.getScreenSets({
        ...params,
        callback: response => handleGigyaResponse(response, resolve, reject)
      });
    });
  }
}

export const gigya = Object.freeze(GigyaFacade.getInstance());
