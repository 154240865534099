import { getEnv } from "@fep/env";
import type { Stage } from "@fep/env/types";

export function injectFonts(): void {
  const env = getEnv(process.env.STAGE as Stage);
  const style = document.createElement("style");
  style.textContent = `
    @font-face {
      font-family: 'Invention';
      src: url('${env.FEP_URL}/assets/fonts/Invention-Italic.eot');
      src: url('${env.FEP_URL}/assets/fonts/Invention-Italic.eot?#iefix') format('embedded-opentype'),
          url('${env.FEP_URL}/assets/fonts/Invention-Italic.woff2') format('woff2'),
          url('${env.FEP_URL}/assets/fonts/Invention-Italic.woff') format('woff'),
          url('${env.FEP_URL}/assets/fonts/Invention-Italic.ttf') format('truetype');
      font-weight: normal;
      font-style: italic;
    }

    @font-face {
      font-family: 'Invention';
      src: url('${env.FEP_URL}/assets/fonts/Invention-ExtraBold.eot');
      src: url('${env.FEP_URL}/assets/fonts/Invention-ExtraBold.eot?#iefix') format('embedded-opentype'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraBold.woff2') format('woff2'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraBold.woff') format('woff'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraBold.ttf') format('truetype');
      font-weight: 800;
      font-style: normal;
    }

    @font-face {
      font-family: 'Invention';
      src: url('${env.FEP_URL}/assets/fonts/Invention-ExtraLightItalic.eot');
      src: url('${env.FEP_URL}/assets/fonts/Invention-ExtraLightItalic.eot?#iefix')
          format('embedded-opentype'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraLightItalic.woff2') format('woff2'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraLightItalic.woff') format('woff'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraLightItalic.ttf') format('truetype');
      font-weight: 200;
      font-style: italic;
    }

    @font-face {
      font-family: 'Invention Extra';
      src: url('${env.FEP_URL}/assets/fonts/Invention-ExtraBoldItalic.eot');
      src: url('${env.FEP_URL}/assets/fonts/Invention-ExtraBoldItalic.eot?#iefix')
          format('embedded-opentype'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraBoldItalic.woff2') format('woff2'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraBoldItalic.woff') format('woff'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraBoldItalic.ttf') format('truetype');
      font-weight: 800;
      font-style: italic;
    }

    @font-face {
      font-family: 'Invention';
      src: url('${env.FEP_URL}/assets/fonts/Invention-LightItalic.eot');
      src: url('${env.FEP_URL}/assets/fonts/Invention-LightItalic.eot?#iefix') format('embedded-opentype'),
           url('${env.FEP_URL}/assets/fonts/Invention-LightItalic.woff2') format('woff2'),
           url('${env.FEP_URL}/assets/fonts/Invention-LightItalic.woff') format('woff'),
           url('${env.FEP_URL}/assets/fonts/Invention-LightItalic.ttf') format('truetype');
      font-weight: 300;
      font-style: italic;
    }

    @font-face {
      font-family: 'Invention';
      src: url('${env.FEP_URL}/assets/fonts/Invention-ExtraLight.eot');
      src: url('${env.FEP_URL}/assets/fonts/Invention-ExtraLight.eot?#iefix') format('embedded-opentype'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraLight.woff2') format('woff2'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraLight.woff') format('woff'),
           url('${env.FEP_URL}/assets/fonts/Invention-ExtraLight.ttf') format('truetype');
      font-weight: 200;
      font-style: normal;
    }

    @font-face {
      font-family: 'Invention';
      src: url('${env.FEP_URL}/assets/fonts/Invention-BoldItalic.eot');
      src: url('${env.FEP_URL}/assets/fonts/Invention-BoldItalic.eot?#iefix') format('embedded-opentype'),
           url('${env.FEP_URL}/assets/fonts/Invention-BoldItalic.woff2') format('woff2'),
           url('${env.FEP_URL}/assets/fonts/Invention-BoldItalic.woff') format('woff'),
           url('${env.FEP_URL}/assets/fonts/Invention-BoldItalic.ttf') format('truetype');
      font-weight: bold;
      font-style: italic;
    }

    @font-face {
      font-family: 'Invention';
      src: url('${env.FEP_URL}/assets/fonts/Invention-Bold.eot');
      src: url('${env.FEP_URL}/assets/fonts/Invention-Bold.eot?#iefix') format('embedded-opentype'),
           url('${env.FEP_URL}/assets/fonts/Invention-Bold.woff2') format('woff2'),
           url('${env.FEP_URL}/assets/fonts/Invention-Bold.woff') format('woff'),
           url('${env.FEP_URL}/assets/fonts/Invention-Bold.ttf') format('truetype');
      font-weight: bold;
      font-style: normal;
    }

    @font-face {
      font-family: 'Invention';
      src: url('${env.FEP_URL}/assets/fonts/Invention-Regular.eot');
      src: url('${env.FEP_URL}/assets/fonts/Invention-Regular.eot?#iefix') format('embedded-opentype'),
           url('${env.FEP_URL}/assets/fonts/Invention-Regular.woff2') format('woff2'),
           url('${env.FEP_URL}/assets/fonts/Invention-Regular.woff') format('woff'),
           url('${env.FEP_URL}/assets/fonts/Invention-Regular.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
        font-family: 'Invention';
        src: url('${env.FEP_URL}/assets/fonts/Invention-Light.eot');
        src: url('${env.FEP_URL}/assets/fonts/Invention-Light.eot?#iefix') format('embedded-opentype'),
        url('${env.FEP_URL}/assets/fonts/Invention-Light.woff2') format('woff2'),
        url('${env.FEP_URL}/assets/fonts/Invention-Light.woff') format('woff'),
        url('${env.FEP_URL}/assets/fonts/Invention-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
      }
  `;
  document.head.appendChild(style);
}
