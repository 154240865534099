import { config } from "@fep/services/config";
import { CaptureEvent, CaptureEventMixin } from "./captureEvent";
import {
  ChangePasswordEvents,
  EditProfileEvents,
  ForgotPasswordEvents,
  LoginEvents,
  RegistrationEvents,
  ResetPasswordEvents,
  ValidationEvents
} from "./categories";

export class GoogleAnalytics extends CaptureEvent {
  screen = "Common";

  changePassword: ChangePasswordEvents;
  editProfile: EditProfileEvents;
  forgotPassword: ForgotPasswordEvents;
  resetPassword: ResetPasswordEvents;
  login: LoginEvents;
  registration: RegistrationEvents;
  validation: ValidationEvents;

  private static _instance: GoogleAnalytics;

  private constructor(mixin: CaptureEventMixin) {
    super(mixin);

    this.changePassword = new ChangePasswordEvents(mixin);
    this.editProfile = new EditProfileEvents(mixin);
    this.forgotPassword = new ForgotPasswordEvents(mixin);
    this.resetPassword = new ResetPasswordEvents(mixin);
    this.login = new LoginEvents(mixin);
    this.registration = new RegistrationEvents(mixin);
    this.validation = new ValidationEvents(mixin);
  }

  static getInstance(mixin: CaptureEventMixin): GoogleAnalytics {
    if (!this._instance) {
      this._instance = new GoogleAnalytics(mixin);
    }

    return this._instance;
  }

  backToLoginEvent(currentScreen: string) {
    this.captureEvent({
      id: "back_to_login_clicked",
      label: "Back to login screen clicked",
      screen: currentScreen,
      action: "navigation"
    });
  }

  backToLinkAccounts(currentScreen: string) {
    this.captureEvent({
      id: "back_to_link_accounts_clicked",
      label: "Back to Link-Accounts screen clicked",
      screen: currentScreen,
      action: "navigation"
    });
  }
}

export const ga = GoogleAnalytics.getInstance({ config });
