import { FepError } from "@fep/services";

export async function getImTokenData(imtoken: string): Promise<{ profile: { email: string } }> {
  const response = await fetch(`${window.gigya.thisScript.globalConf.checkImTokenURL}?imtoken=${imtoken}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new FepError(`HTTP error! Status: ${response.status}`, response);
  }

  return await response.json();
}
