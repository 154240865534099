import { isLoggedIn } from "@fep/functions";

const noStorePages = new RegExp([
  "my-profile"
].join("|"));

export const handleBFCacheRestore = () => {
  window.addEventListener("pageshow", event => {
    if (event.persisted && noStorePages.test(location.pathname)) {
      document.body.style.visibility = "hidden";
      return isLoggedIn().then(isLoggedIn => {
        if (!isLoggedIn) {
          return window.location.reload();
        }
        document.body.style.visibility = "visible";
      });
    }
  });
};
