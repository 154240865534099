/* eslint-disable @typescript-eslint/no-explicit-any */

export const bindMethods = <T>(instance: T): void => {
  const prototype = Object.getPrototypeOf(instance);
  Object.getOwnPropertyNames(prototype).forEach(method => {
    const descriptor = Object.getOwnPropertyDescriptor(prototype, method);
    if (descriptor && typeof descriptor.value === "function" && method !== "constructor") {
      // Bind the method to the instance
      (instance as any)[method] = descriptor.value.bind(instance);
    }
  });
};
