import { IScreenSetMapper, IScreenSetOptions, IScreenSetMapperParams } from "@fep/interfaces";

export function getScreenSetFromMapper(
  {
    market,
    portal,
    screenSetKey
  }: IScreenSetMapperParams
): IScreenSetOptions {
  const {
    getDeepValue
  } = window.gigya.thisScript.globalConf;

  const mapper: IScreenSetMapper = {
    DE: {
      medicalPortal: {
        registration: { name: "DE-Registration-medicalPortal", plugins: [] },
        myProfile: { name: "DE-My-Profile-medicalPortal", plugins: [] }
      },
      mConnect: {
        registration: { name: "DE-Registration-mConnect", plugins: ["phones"] },
        myProfile: { name: "DE-My-Profile-mConnect", plugins: [] }
      },
      customerLink: {
        registration: { name: "DE-Registration-customerLink", plugins: ["phones"] },
        myProfile: { name: "DE-My-Profile-customerLink", plugins: [] }
      }
    },
    common: {
      communication: { name: "Communication-Preferences", plugins: ["phones"] },
      login: { name: "Login", plugins: [] },
      changePassword: { name: "Change-Password", plugins: [] },
      providePassword: { name: "Provide-Password", plugins: [] },
      forgotPassword: { name: "Forgot-Password", plugins: [] },
      createPassword: { name: "Create-Password", plugins: [] }
    }
  };

  return getDeepValue(mapper, `common.${screenSetKey}`) || getDeepValue(mapper, `${market}.${portal}.${screenSetKey}`);
}
