import { CaptureEvent } from "../captureEvent";
import { getGaScreenName } from "../screenNameMapper";

export class EditProfileEvents extends CaptureEvent {
  screen = getGaScreenName("my-account-screen");

  profileUpdateSuccessEvent(metadata: Record<string, string>) {
    this.validateMetadata(metadata, ["email"]);

    this.captureEvent({
      ...metadata,
      action: "success",
      id: "edit_profile_success",
      label: "Profile successfully updated"
    });
  }

  profileUpdateFailureEvent(metadata: Record<string, string>) {
    this.validateMetadata(metadata, ["email", "reason"]);

    this.captureEvent({
      ...metadata,
      action: "fail",
      id: "edit_profile_fail",
      label: "Profile updating failed"
    });
  }

  communicationUpdateSuccessEvent(metadata: Record<string, string | object>) {
    this.validateMetadata(metadata, ["email", "consents"]);

    this.captureEvent({
      ...metadata,
      action: "success",
      id: "edit_profile_success",
      label: "Profile successfully updated"
    }, getGaScreenName("communication-screen"));
  }
}
