export function getDeepValue<R, T>(obj: R, path: string): T | undefined {
  const paths = path.split(".") as Array<keyof R>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let current: any = obj;
  let i;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
}
