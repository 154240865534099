import { parse } from "json5";
import { IConfig } from "@fep/interfaces";
import { FepError } from "@fep/services";
import { FepGigyaEvents, events } from "./events";
import { bindMethods } from "@fep/util";

export interface FepConfigMixin {
  events: FepGigyaEvents;
}

export class FepConfig {
  private static _instance: FepConfig;
  private _data!: IConfig;

  // mixin
  events: FepGigyaEvents;

  private constructor(mixin: FepConfigMixin) {
    this.events = mixin.events;
    bindMethods(this);
  }

  static getInstance(mixin: FepConfigMixin): FepConfig {
    if (!this._instance) {
      this._instance = new FepConfig(mixin);
    }

    return this._instance;
  }

  get data() {
    if (!this._data) {
      this.data = this._fetchAndParse() ?? {} as IConfig;
    }

    return this._data;
  }

  set data(rawData: IConfig) {
    this._validate(rawData);

    this._data = {
      ...rawData,
      ssoEnabled: rawData.ssoEnabled ?? true,
      gaEnabled: rawData.gaEnabled ?? true
    };

    if (rawData.events?.onLogin) {
      this.events.onLogin = rawData.events.onLogin;
    }

    if (rawData.events?.onLogout) {
      this.events.onLogout = rawData.events.onLogout;
    }
  }

  private _validate(rawData: IConfig) {
    const requiredFields: (keyof IConfig)[] = ["apiKey", "lang", "market"];
    const validPortals: (typeof rawData.portal)[] = ["medicalPortal", "mConnect", "customerLink"];

    for (const field of requiredFields) {
      if (!rawData[field]) {
        throw new FepError(`Configuration validation error: '${field}' is required but was not provided.`);
      }
    }

    if (rawData.portal && !validPortals.includes(rawData.portal)) {
      throw new FepError(`Configuration validation error: 'portal' must be one of
        ${validPortals.join(", ")}, but was '${rawData.portal}'.`);
    }
  }

  private _fetchAndParse(): IConfig | undefined {
    const configTextObject = document.querySelector('script[src$="fep.js"]')?.textContent?.trim();

    if (configTextObject) {
      try {
        return parse(configTextObject);
      } catch (e) {
        throw new FepError("Failed to parse the FEP configuration script: Invalid JSON format. Please check the script content.");
      }
    }
  }
}

export const config = FepConfig.getInstance({ events });
