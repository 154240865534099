import { getAccountInfo } from "@fep/functions";
import { IGigyaEvents } from "@fep/interfaces";
import { ga, logger } from "@fep/services";

export async function onLogin(callback: IGigyaEvents["onLogin"]) {
  try {
    const account = await getAccountInfo();
    ga.login.loginSuccessEvent({ email: account.profile.email, type: "traditional" });
    await callback!(account);
  } catch (error) {
    logger.error(error);
  }
}
