import { FepError } from "@fep/services/error";
import { CaptureEvent } from "../captureEvent";
import type { ICaptureEventOptions } from "@fep/interfaces";

export class RegistrationEvents extends CaptureEvent {
  screen = "Registration";

  private currentRegistrationId?: string;

  startRegistrationEvent(metadata: Record<string, string>) {
    this.validateMetadataType(metadata);
    this.currentRegistrationId = crypto.randomUUID();

    this.captureEvent({
      ...metadata,
      action: "start",
      id: "sign_up_start",
      label: "Registration started"
    });
  }

  navigateEvent(metadata: Record<string, string>) {
    const { from, to } = metadata;

    if (!from || typeof from !== "string" || !to || typeof to !== "string") {
      throw new FepError("Both 'from' and 'to' properties must be specified and be of type string.");
    }

    this.captureEvent({
      ...metadata,
      action: "navigation",
      id: "sign_up_progression",
      label: `Navigated registration from ${from} to ${to}`
    });
  }

  registrationSuccessEvent(metadata: Record<string, string | object>) {
    this.validateMetadataType(metadata);
    this.validateMetadata(metadata, ["email", "consents"]);

    this.captureEvent({
      ...metadata,
      action: "success",
      id: "sign_up",
      label: "Registration successful"
    });

    this.currentRegistrationId = undefined;
  }

  registrationFailureEvent(metadata: Record<string, string>) {
    this.validateMetadataType(metadata);
    this.validateMetadata(metadata, ["email", "reason"]);

    this.captureEvent({
      ...metadata,
      action: "fail",
      id: "sign_up_fail",
      label: "Registration failed"
    });
  }

  private validateMetadataType(metadata: Record<string, string | object>) {
    const validTypes = ["traditional", "social"];

    if (!validTypes.includes(metadata.type as string)) {
      throw new FepError("Property 'type' must be either 'traditional' or 'social'");
    }
  }

  protected captureEvent(captureEvent: ICaptureEventOptions) {
    if (!this.currentRegistrationId) {
      throw new FepError("startRegistrationEvent must be called before any other registration event to init the registration process.");
    }

    super.captureEvent({
      ...captureEvent,
      registrationId: this.currentRegistrationId
    });
  }
}
