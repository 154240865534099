import { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { logger } from "@fep/services";

export async function showEmailVerificationScreen(options: IShowScreenOptions | string) {
  let showScreenOptions: Partial<IShowScreenOptions> = {};
  if (typeof options === "string") {
    logger.warn(
      "Deprecated API usage, showEmailVerificationScreen should be called with object instead of string"
    );
    showScreenOptions.containerID = options;
  } else {
    showScreenOptions = { ...showScreenOptions, ...options };
  }

  const emailVerified = new URLSearchParams(window.location.search).get("verified");
  const startScreen = emailVerified === "true" ? "gigya-email-verification-success-screen" : "gigya-email-verification-screen";

  window.gigya.accounts.showScreenSet({
    screenSet: "Email-Verification",
    context: {
      market: window.fep.market,
      redirect: showScreenOptions.redirect,
      registrationPath: showScreenOptions.registrationPath
    },
    lang: window.fep.lang,
    containerID: showScreenOptions.containerID,
    startScreen
  });

}
