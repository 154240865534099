import { IGigyaEvents } from "@fep/interfaces";
import { logger } from "@fep/services";

export async function onLogout(callback: IGigyaEvents["onLogout"]) {
  try {
    await callback!();
  } catch (error) {
    logger.error(error);
  }
}
