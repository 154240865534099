import { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { showScreenSet } from "@fep/gigyaFunction";

export async function showRegistrationScreen(options: IShowScreenOptions) {
  await showScreenSet({
    screenSet: "Common-screenSet",
    containerID: options.containerID,
    screenSetKey: "registration",
    redirect: options.redirect
  });
}
